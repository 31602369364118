<!--
  作者：顾先生
-->
<template>
  <div>
    <div class="titleSlot">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'title1',
  // 父子传值
  props: ['title'],
  // 页面初次解析完成
  created () {
  }
}
</script>

<style scoped lang="less">
.titleSlot{
  position: relative;
  z-index: 1;
  margin-top: 20px;
  padding-left: 12px;
  width: 243px;
  height: 45px;
  font-size: 25px;
  color: #710101;
  &:before{
    position: absolute;
    left: 0;
    content: "";
    width: 8px;
    height: 45px;
    background-color: #de343f;
  }
  &:after{
    position: absolute;
    left: 8px;
    top: 20px;
    z-index: -1;
    content: "";
    width: 150px;
    height: 25px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.5), #ffd7d7);
  }
}
</style>
