<!--
  作者：顾先生
-->
<template>
  <div>
    <div class="wapper paiBianContent">
      <title1 title="牌匾邮寄进度"></title1>
      <div class="paiBianContent_p1">已加入工程的企业，您可以通过【鲸诚信】给您发送的通知短信里的“快递单号"在EMS官网查询，或点击下方的“按钮”查询进度</div>
      <el-button class="paiBianContent_Box" icon="el-icon-search" @click="btn">点击查询荣誉牌匾邮寄进度</el-button>
      <public-href></public-href>
    </div>
    <PublicBottom></PublicBottom>
  </div>
</template>

<script>
import title1 from '@/components/title1.vue'
import PublicBottom from '@/components/publicBottom.vue'
import PublicHref from '@/components/PublicHref.vue'

export default {
  name: 'paiBianContent',
  // 注册组件
  components: { PublicHref, PublicBottom, title1 },
  // 注册方法
  methods: {
    btn() {
      window.open('https://www.ems.com.cn/queryList')
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.paiBianContent{
  margin: 0 auto;
  .paiBianContent_p1{
    margin-top: 40px;
    letter-spacing: 2px;
    font-size: 19px;
  }
  .paiBianContent_Box{
    display: block;
    width: 400px;
    background-color: #dd1216;
    margin:  40px auto 500px;
    color: white;
    font-size: 19px;
  }
}
</style>
