<!--
  作者：顾先生
-->
<template>
  <div class="PublicHref">
    <div class="PublicHrefBox">
      <div class="PublicHrefBox_p1">相关链接</div>
      <div class="PublicHrefBox_p2">
        <span class="PublicHrefBox_p2_span"><a href="https://www.gsxt.gov.cn/index.html" target="_blank">国家企业信用信息公示系统</a></span>
        <span class="PublicHrefBox_p2_span"><a href="https://www.creditchina.gov.cn" target="_blank">信用中国</a></span>
        <span class="PublicHrefBox_p2_span"><a href="http://zxgk.court.gov.cn" target="_blank">中国执行信息公开网</a></span>
        <span class="PublicHrefBox_p2_span"><a href="https://wenshu.court.gov.cn" target="_blank">中国裁判文书网</a></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PublicHref',
  // 注册组件
  components: {},
  // 注册方法
  methods: {},
  // 数据渲染
  data () {
    return {

    }
  }
}
</script>

<style scoped lang="less">
.PublicHref{
  padding: 7px;
  background-color: #fff;
  .PublicHrefBox{
    padding: 10px 20px;
    height: 120px;
    background-image: linear-gradient(180deg,
    #ffeee7 0%,
    #fff7f7 100%);
    .PublicHrefBox_p1{
      font-size: 25px;
      color: #710000;
    }
    .PublicHrefBox_p2{
      margin-left: 200px;
      margin-top: 10px;
      .PublicHrefBox_p2_span{
        margin-right: 40px;
        font-size: 20px;
        a{
          color: #000000;
        }
        transition: all .3s;
        &:hover{
          cursor: pointer;
          color: #710000;
        }
      }
    }
  }
}
</style>
