<!--
  作者：顾先生
-->
<template>
  <div>
    <div class="wapper guanYuContent">
      <title1 title="关于我们"></title1>
      <div class="guanYuContent_p1">俗话说的好“百姓的口碑才是真正的无价之宝，远胜金杯银杯”，自古以来，讲诚信的人总是受到人们的赞扬和传播，特别是在商业领域，诚信经营的企业或者商户总能屹立不倒。</div>
      <div class="guanYuContent_p1">正所谓“人无诚信难以安身立命，业无诚信难以繁荣昌盛”，所以人与人之间的交往、做生意等，讲诚信是能持久的重要因素。“诚信企业计划”是由鲸诚信发起，为有意愿建立诚信经营形象，让诚信经营的理念可视化、展现化，从而降低中小企业经营成本，获取更多资源和机会的信用工程。</div>
      <div class="guanYuContent_p1">诚信企业计划旨在“为中小企业诚信赋予更大价值”，依据公平、公正、公开的方法对中小企业信用进行审核并进行信用公示，达到降低信用信息不对称产生的成本，让企业诚信形象得到提升和更多展示，从而达到“褒扬诚信、惩戒失信”的目标。</div>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <public-href></public-href>
    </div>
    <PublicBottom></PublicBottom>
  </div>
</template>

<script>
import PublicBottom from '@/components/publicBottom.vue'
import PublicHref from '@/components/PublicHref.vue'
import title1 from '@/components/title1.vue'

export default {
  name: 'guanYuContent',
  // 注册组件
  components: {
    title1,
    PublicHref,
    PublicBottom
  },
  // 注册方法
  methods: {},
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.guanYuContent{
  margin: 0 auto;
  .guanYuContent_p1{
    text-indent: 2em;
    line-height: 40px;
    margin: 40px;
    font-size: 20px;
    font-weight: bold;
  }
}
</style>
