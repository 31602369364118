<!--
  作者：顾先生
-->
<template>
  <div>
    <!--    头部-->
    <div class="headBox1">
      <div class="headBox1_One wapper">
        <div class="headBox1_One_p1">欢迎访问鲸诚信诚信企业计划官方网站</div>
        <div class="headBox1_One_p2">
          <div class="headBox1_One_flex1">
            <img src="../assets/image/shizhong.png" class="headBox1_One_flex1_img">
            <div class="headBox1_One_flex1_font1">周一到周五<br>8:00-20:00</div>
          </div>
          <div class="headBox1_One_flex2">
            <img src="../assets/image/dianhua.png" class="headBox1_One_flex1_img">
            <div class="headBox1_One_flex1_font1">400-056-5663</div>
          </div>
        </div>
      </div>
    </div>
    <!--    头部主体-->
    <div class="headBox2 clearfix">
      <div class="wapper" style="margin: 0 auto 0">
        <div class="headBox2_dingWeiFlex">
          <div class="headBox2_dingWei1">
            <img src="../assets/image/logo.png" class="headBox2_dingWei1_img">
            <div class="headBox2_dingWei1_p1">
              <div class="headBox2_dingWei1_span2">诚信企业计划</div>
              <div class="headBox2_dingWei1_span3">jingchengxinyong.cn</div>
            </div>
            <div class="headBox2_dingWei1_p2">
              <div class="headBox2_dingWei1_p2_box1">河北鲸诚信用服务有限公司</div>
              <div class="headBox2_dingWei1_p2_box1">为中小企业诚信赋予更大价值</div>
            </div>
          </div>
          <div class="headBox2_dingWei2">
            <div class="headBox2_dingWei2_p1">企业公示查询</div>
            <el-input v-model="searchCompany" placeholder="请输入企业全称查询公示详情">
              <el-button slot="append" icon="el-icon-search" class="sousuo" @click="callChildMethod">搜索</el-button>
            </el-input>
          </div>
        </div>
      </div>
    </div>
    <!--    文字选择-->
    <div class="headBox3">
      <div class="headBox3Box wapper">
        <ul class="headBox3Box_ul">
          <li :class=" liShow1 ? 'headBox3Box_li headBox3Box_li_active':'headBox3Box_li'" @click="liShowOne(1)"><span :class=" liShow1 ? 'headBox3Box_span':''">首页</span></li>
          <li :class=" liShow2 ? 'headBox3Box_li headBox3Box_li_active':'headBox3Box_li'" @click="liShowTwo(2)"><span :class=" liShow2 ? 'headBox3Box_span':''">诚信企业计划</span></li>
          <li :class=" liShow3 ? 'headBox3Box_li headBox3Box_li_active':'headBox3Box_li'" @click="liShowThree(3)"><span :class=" liShow3 ? 'headBox3Box_span':''">信用公示</span></li>
          <li :class=" liShow4 ? 'headBox3Box_li headBox3Box_li_active':'headBox3Box_li'" @click="liShowFour(4)"><span :class=" liShow4 ? 'headBox3Box_span':''">牌匾邮寄进度</span></li>
          <li :class=" liShow5 ? 'headBox3Box_li headBox3Box_li_active':'headBox3Box_li'" @click="liShowFive(5)"><span :class=" liShow5 ? 'headBox3Box_span':''">关于我们</span></li>
        </ul>
      </div>
    </div>
    <headerContent v-show="liShow1" ref="child1"></headerContent>
    <cheng-xin-content v-show="liShow2"></cheng-xin-content>
    <xin-yong-content v-show="liShow3" ref="child2"></xin-yong-content>
    <pai-bian-content v-show="liShow4"></pai-bian-content>
    <guan-yu-content v-show="liShow5"></guan-yu-content>
  </div>
</template>

<script>

import headerContent from '@/components/headerContent.vue'
import ChengXinContent from '@/components/chengXinContent.vue'
import PaiBianContent from '@/components/paiBianContent.vue'
import GuanYuContent from '@/components/guanYuContent.vue'
import XinYongContent from '@/components/xinYongContent.vue'

export default {
  name: 'Head',
  // 注册组件
  components: { XinYongContent, GuanYuContent, PaiBianContent, ChengXinContent, headerContent },
  // 注册方法
  methods: {
    liShowOne(num) {
      this.liShow1 = true
      this.liShow2 = false
      this.liShow3 = false
      this.liShow4 = false
      this.liShow5 = false
    },
    liShowTwo(num) {
      console.log(num)
      this.liShow1 = false
      this.liShow2 = true
      this.liShow3 = false
      this.liShow4 = false
      this.liShow5 = false
    },
    liShowThree(num) {
      this.liShow1 = false
      this.liShow2 = false
      this.liShow3 = true
      this.liShow4 = false
      this.liShow5 = false
    },
    liShowFour(num) {
      this.liShow1 = false
      this.liShow2 = false
      this.liShow3 = false
      this.liShow4 = true
      this.liShow5 = false
    },
    liShowFive(num) {
      this.liShow1 = false
      this.liShow2 = false
      this.liShow3 = false
      this.liShow4 = false
      this.liShow5 = true
    },
    callChildMethod() {
      if (this.liShow1) {
        this.$refs.child1.chaKan(this.searchCompany)
      } else if (this.liShow3) {
        this.$refs.child2.chaKan(this.searchCompany)
      }
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      searchCompany: '',
      // eslint-disable-next-line vue/no-dupe-keys
      liShow1: true,
      // eslint-disable-next-line vue/no-dupe-keys
      liShow2: false,
      // eslint-disable-next-line vue/no-dupe-keys
      liShow3: false,
      // eslint-disable-next-line vue/no-dupe-keys
      liShow4: false,
      // eslint-disable-next-line vue/no-dupe-keys
      liShow5: false
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.headBox1{
  width: 100%;
  height: 64px;
  background-color: #dfdcdb;
  .headBox1_One{
    margin: 0 auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headBox1_One_p1{
      font-size: 17px;
      line-height: 64px;
      letter-spacing: 1px;
      color: #010101;
    }
    .headBox1_One_p2{
      display: flex;
      .headBox1_One_flex1{
        display: flex;
        align-items: center;
        margin-right: 40px;
        .headBox1_One_flex1_img{
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
        .headBox1_One_flex1_font1{
          text-align: center;
          font-size: 13px;
          letter-spacing: 1px;
          color: #010101;
        }
      }
      .headBox1_One_flex2{
        display: flex;
        align-items: center;
        .headBox1_One_flex1_img{
          width: 25px;
          height: 25px;
          margin-right: 10px;
        }
        .headBox1_One_flex1_font1{
          text-align: center;
          font-size: 13px;
          letter-spacing: 1px;
          color: #010101;
        }
      }
    }
  }
}
.headBox2{
  position: relative;
  width: 100%;
  height: 350px;
  background-image: url("../assets/image/haiou.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .headBox2_dingWeiFlex{
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
  }
  .headBox2_dingWei1{
    width: 520px;
    height: 90px;
    display: flex;
    align-items: center;
    .headBox2_dingWei1_img{
      width: 80px;
      height: 70px;
      margin-right: 20px;
    }
    .headBox2_dingWei1_p1{
      margin-right: 20px;
      .headBox2_dingWei1_span1{
        text-align: center;
        font-size: 18px;
        color: #ffffff;
      }
      .headBox2_dingWei1_span2{
        font-size: 20px;
        color: #ffffff;
      }
      .headBox2_dingWei1_span3{
        font-size: 15px;
        color: #ffffff;
      }
    }
    .headBox2_dingWei1_p2{
      padding: 4px;
      background-color: #fff;
      text-align: center;
      .headBox2_dingWei1_p2_box1,.headBox2_dingWei1_p2_box2{
        font-size: 16px;
        letter-spacing: 1px;
        color: #dc2e36;
      }
    }
  }
  .headBox2_dingWei2{
    width: 450px;
    height: 70px;
    .headBox2_dingWei2_p1{
      font-size: 16px;
      letter-spacing: 2px;
      color: #ffffff;
      margin-bottom: 5px;
    }
    .sousuo{
      font-size: 16px;
      color: black;
    }
  }
}
.headBox3{
  margin-bottom: 40px;
  height: 80px;
  background-color: #e93b49;
  .headBox3Box{
    position: relative;
    margin: 0 auto 0;
    .headBox3Box_ul{
      position: relative;
      display: flex;
      .headBox3Box_li_active{
        background-color: #dc2e36 !important;
      }
      .headBox3Box_li{
        flex: 1;
        height: 80px;
        line-height: 80px;
        text-align: center;
        font-size: 21px;
        letter-spacing: 1px;
        color: #ffffff;
        transition: all .3s;
        &:hover{
          cursor: pointer;
          background-color: #dc2e36;
        }
      }
      .headBox3Box_span{
        padding-bottom: 12px;
        border-bottom: 2px solid white;
      }
    }
  }
}

</style>
