import serviceAxios from '../index'
// get 请求需要传 params，post 请求需要传 data
// 获取公司
export const companyInfo = (data) => {
  return serviceAxios({
    url: '/index.php/Plaque/pageMedia',
    method: 'post',
    data
  })
}
// 查看证书
export const chakanCompany = (data) => {
  return serviceAxios({
    url: '/index.php/Plaque/orSsl',
    method: 'post',
    data
  })
}
